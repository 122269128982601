// Importing action types
import {
  FETCH_VIEWALL_REQUEST,
  FETCH_VIEWALL_SUCCESS,
  FETCH_VIEWALL_FAILURE,
} from "../../actions/getViewallActions";

// Define the initial state for the reducer
const initialState = {
  viewallData: null,  // Initial value for viewallData is null
  isLoading: false,   // Initial value for isLoading is false
  isError: null,      // Initial value for isError is null
};

// Define the GetViewAllReducer function
const GetViewAllReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VIEWALL_REQUEST:
      // When the FETCH_VIEWALL_REQUEST action is dispatched
      return {
        ...state,
        isLoading: true,   // Set isLoading to true
        isError: null,     // Reset isError to null
      };
    case FETCH_VIEWALL_SUCCESS:
      // When the FETCH_VIEWALL_SUCCESS action is dispatched
      return {
        ...state,
        viewallData: action.payload,  // Update viewallData with the payload from the action
        isLoading: false,             // Set isLoading to false
        isError: null,                // Reset isError to null
      };
    case FETCH_VIEWALL_FAILURE:
      // When the FETCH_VIEWALL_FAILURE action is dispatched
      return {
        ...state,
        viewallData:[],
        isLoading: false,             // Set isLoading to false
        isError: action.payload,      // Update isError with the payload from the action
      };
    default:
      // If none of the above action types match, return the current state
      return state;
  }
};

export default GetViewAllReducer;
