// sagas.js

import { call, put, takeLatest } from "redux-saga/effects";
import { LOGINAPI } from "../../../utility/constants";
import axios from "axios";
import {
  VERIFY_OTP_REQUEST,
  verifyOtpSuccess,
  verifyOtpFailure,
} from "../../actions/getVerifyOtpActions";

function* getVerifyOtp(action) {

  try {

    let isLoginbyMobile = action.payload.isLoginbyMobile
    // console.log('action.payload.otp', typeof action.payload.otp)
    const payload = new FormData();
    if (!isLoginbyMobile) {
      payload.append("email", action.payload.email);
      localStorage.setItem("email", action.payload.email);
      payload.append("account_type", "2");
    } else {
      payload.append(
        "mobile",
        localStorage.getItem("countryCallingcode") + action.payload.mobile
      );
      localStorage.setItem("mobile", action.payload.mobile);
      payload.append("account_type", "1");
    }
    payload.append("otp", action.payload.otp);

    payload.append("tag", "1");
    payload.append("country", localStorage.getItem("countryCode"));
    payload.append("device_type", action.payload.name);
    payload.append("device_name", action.payload.name + " " + "(" + action.payload.os + ")");
    payload.append("name", action.payload.name + " " + "(" + action.payload.os + ")");
    payload.append("version", localStorage.getItem("version"));
    payload.append("os", action.payload.os);
    payload.append("uuid", localStorage.getItem("uuid"));

    const response = yield call(axios.post, LOGINAPI, payload, {
      headers: {
        // 'Content-Type': 'multipart/form-data',
        token: localStorage.getItem("token"),
      },
    });

    if (response.data.status === "Success") {
      let token = response.data.token;
      localStorage.setItem("token", token);
      localStorage.setItem("isConnected", true);

      yield put(verifyOtpSuccess(response));
    }
  } catch (error) {

    let errorResponse = error.response;
    if (errorResponse.status === 400 && errorResponse.data.status === "Failed") {

      yield put(verifyOtpSuccess(errorResponse));
    } else {

    }
  }
}

export function* getVerifyOtpSaga() {
  yield takeLatest(VERIFY_OTP_REQUEST, getVerifyOtp);
}
export default getVerifyOtpSaga;
