import React, { useEffect, useState, useRef } from "react";
import RokuLogo from '../../../../assets/images/roku.png' // Importing Roku logo image
import GooglePlayStore from "../../../../assets/images/gpay.svg"; // Importing Google Play Store image
import AppStore from "../../../../assets/images/app_store.svg"; // Importing App Store image
import TizenImg from "../../../../assets/images/tizen.svg";
import WebOsImg from "../../../../assets/images/webos.svg";
import { Link } from "react-router-dom"; // Importing Link component from react-router-dom
import Grid from "@mui/material/Grid"; // Importing Grid component from Material-UI
import { connect } from "react-redux"; // Importing connect function from react-redux
import { fetchFooterMenu } from "../../../../Redux/actions/fecthFooterMenuActions"; // Importing fetchFooterMenu action
import { Button } from "@mui/material"; // Importing Button component from Material-UI
import HomeIcon from '@mui/icons-material/Home'; // Importing HomeIcon component from Material-UI
import SearchIcon from '@mui/icons-material/Search'; // Importing SearchIcon component from Material-UI
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange'; // Importing CurrencyExchangeIcon component from Material-UI
import { ANDROIDAPP, ROKUAPP, DONATIONURL, IOSAPP, LOGO } from "../../../../utility/constants"; // Importing constants for app URLs and logos
import CustomModal from "../../../../components/modal/modalCustom"; // Importing CustomModal component
import Skeleton from "@mui/material/Skeleton"; // Importing Skeleton component from Material-UI
import Box from "@mui/material/Box"; // Importing Box component from Material-UI
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Importing ArrowBackIcon component from Material-UI
import UAParser from 'ua-parser-js';
const FooterMenu = ({ isMedium, menuItems, fetchFooterMenu, token, menu, commonData }) => {
  // Refs
  const searchRef = useRef(null); // Reference for search input field

  // State
  const [isMacOS, setIsMacOS] = useState(false);
  const [footerMenuData, setFooterMenuData] = useState([]); // State for storing footer menu data
  const windowSize = useRef([window.innerWidth, window.innerHeight]); // Reference for window size
  const [windowWidth, setwindowWidth] = useState(windowSize.current[0]); // State for window width
  const [isOpenSearch, setIsOpenSearch] = useState(""); // State for controlling search visibility
  const [open, setOpen] = React.useState(false); // State for controlling modal visibility
  const [state, setState] = useState({ // State for managing various state variables
    sideMenuItems: [],
    topMenu: [],
    keyword: "",
    focusOnSearch: false,
    isLogin: false,
    commondataItems: {}
  });
  const {
    commondataItems
  } = state;

  // Effects
  useEffect(() => {
    const parser = new UAParser();
    const os = parser.getOS();
    // console.log('os', os)
    setIsMacOS(os.name === 'Mac OS');
  }, []);
  useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Adding class to root element if userAgent indicates iPad/iPhone/iPod
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.querySelector('#root').classList.add('ipad')
    }

    const footerMenuApiCall = async () => { // Function to fetch footer menu data
      try {
        if (isMedium === false) { // Only fetch if isMedium is false
          await fetchFooterMenu();
        }
      } catch (error) { }
    };

    const timeout = setTimeout(footerMenuApiCall, 600); // Set a timeout for fetching footer menu data

    // Clean up the timeout if the component is unmounted or the API call completes
    return () => {
      clearTimeout(timeout);
    };
  }, [fetchFooterMenu]);

  useEffect(() => { // Effect to set footer menu data when menuItems change
    if (commonData ?? false) {
      try {
        if (commonData.status === "Success") {

          setState((prevState) => ({
            ...prevState,
            commondataItems: commonData.data,
          }));
        }
      } catch (error) {

      }
    }
    if (menuItems ?? false) {
      try {
        if (menuItems?.status === "Success") {
          setFooterMenuData(menuItems.menu);
        }
      } catch (error) {

      }
    }

  }, [menuItems, commonData]);

  // Event Handlers
  const redirectTodonation = (value) => { // Function to redirect to donation URL
    window.open(value, '_blank');
  };

  const searchCall = () => { // Function to handle search opening
    document.querySelector('html').classList.add('overflow-hidden');
    setIsOpenSearch(true);
    setTimeout(() => {
      searchRef.current.focus();
    }, 100);
  };

  const handleClose = () => { // Function to handle modal closing
    setOpen(false);
    document.querySelector('html').classList.remove('overflow-hidden');
  };

  const handleInputSearchChange = (event) => { // Function to handle input change in search field
    const keywordvalue = event.target.value;
    setState((prevState) => ({
      ...prevState,
      keyword: keywordvalue,
    }));
  };

  const handleKeyPress = (event) => { // Function to handle key press in search field
    if (event.key === "Enter") {
      const searchKeyword = state.keyword;
      if (searchKeyword?.trim()) {
        const location = `/search/${state.keyword.trim()}`;
        window.location.href = location;
      } else {
        return false;
      }
    }
  };

  const gotoSearchPage = () => { // Function to navigate to search page
    const searchKeyword = state.keyword;
    if (searchKeyword?.trim()) {
      const location = `/search/${state.keyword.trim()}`;
      window.location.href = location;
    } else {
      return false;
    }
  };


  // Render
  return (
    <>
      {/* Footer content */}
      <footer className="mt-2" id="footermain">
        <Grid
          container
          spacing={0}
          sx={{ m: "0", }}
          className="w-100 footerContainer"
        >
          {/* Logo section */}

          <Grid item md={2} className="pt-0 item1 flex-column">
            <div className="footer_logo_wrapper">
              <Link to="/">
                {localStorage.getItem("logo") ? (
                  <img
                    src={`${localStorage.getItem("logo") ? localStorage.getItem("logo") : menu.logo_url}/width=60/height=80/quality=60`}
                    alt="Logo"
                    className="footer_logo"
                  />
                ) : (
                  <Skeleton
                    animation="wave"
                    variant="rectangular"
                    width={120}
                    height={40}
                    style={{ borderRadius: "50px" }}
                  ></Skeleton>
                )}
              </Link>
              <span className="logoTxt">Shalom Television</span>
            </div>
          </Grid>


          {/* Footer menu items */}
          <Grid item md={6} className="pt-0 footer_nav item2 px-0">
            <ul className="list list-inline mb-0 header_Tabs">
              {footerMenuData?.length > 0 &&
                footerMenuData.map((item, i) => (
                  <li className="list-inline-item" key={i}>
                    <Link
                      to={{
                        pathname: `/static/${item.slug}`,
                        state: {
                          name: item.cat_name,
                          slug: item.slug
                        } // Add your state data here
                      }}
                      exact="true"
                      aria-label="Toggle navigation"
                    >
                      {item.cat_name}
                    </Link>
                  </li>
                ))}
            </ul>
          </Grid>


          {/* App download section start */}
          <Grid
            item
            md={4}
            className="pt-0 px-5 ml-2  item3 align-items-center justify-content-center"
          >
            {isMacOS === true ? <p className="align-items-center justify-content-center text-white mb-1" >Also Availble on :</p> : ''}
            <div className="d-flex align-items-center justify-content-center flex-wrap gap-2">
              {commondataItems?.live_store_url?.android_app ? <span
                className="cursor mx-1"
                onClick={() => window.open(commondataItems?.live_store_url?.android_app, "_blank")}
              >
                <img
                  src={GooglePlayStore}
                  width="90px"
                  alt="G Pay"
                />
              </span> : ''}

              {commondataItems?.live_store_url?.ios_app ? <span className="cursor mx-1" onClick={() => window.open(commondataItems?.live_store_url?.ios_app, "_blank")}>
                <img
                  src={AppStore}
                  width="90px"
                  alt="App Store"
                />
              </span> : ''}

              {commondataItems?.live_store_url?.roku_app ? < span
                className="cursor mx-1"
                onClick={() => window.open(commondataItems?.live_store_url?.roku_app, "_blank")}
              >
                <img
                  src={RokuLogo}
                  width="90px"
                  alt="Tizen"
                />
              </span> : ''}

              {commondataItems?.live_store_url?.samsung_app ? <span
                className="cursor mx-1"
                onClick={() => window.open(commondataItems?.live_store_url?.samsung_app, "_blank")}
              >
                <img
                  src={TizenImg}
                  width="90px"
                  alt="WEB OS"
                />
              </span> : ''}

              {commondataItems?.live_store_url?.lg_tv_app ? <span
                className="cursor mx-1"
                onClick={() => window.open(commondataItems?.live_store_url?.lg_tv_app, "_blank")}
              >
                <img
                  src={WebOsImg}
                  width="90px"
                  alt="G Pay"
                />
              </span> : ''}
            </div>
          </Grid>
        </Grid>
      </footer >

      {/* Copyright */}
      < div className="pl-5 pr-5 pt-3 pb-3 copyright" >
        <p className="text-center mb-0">&copy; {commondataItems?.copyright?.text_data}</p>
      </div >

      {/* Fixed footer navigation */}
      {
        windowWidth < 1200 &&
        <div className="fixedFooterNav align-items-center justify-content-between" id="aditionalfooter">
          <Button>
            <Link to="/" className="d-flex flex-column justify-content-center"> <HomeIcon className="m-auto" />
              <label>Home</label></Link>
          </Button>

          <Link to="/search">
            <Button>
              <SearchIcon />
              <label>Search</label>
            </Button>
          </Link>

          <Button onClick={() => redirectTodonation(commondataItems?.donation?.url)}>
            <CurrencyExchangeIcon />
            <label>{commondataItems?.donation?.title}</label>
          </Button>
        </div>
      }
    </>
  );
};

const mapStateToProps = (state) => ({ // Mapping Redux state to props
  menu: state.menu.topMenuData,
  commonData: state.common.commonData,
  menuItems: state.footerMenu.menuItems,
  loading: state.footerMenu.loading,
  error: state.footerMenu.error,
});

const mapDispatchToProps = { // Mapping Redux actions to props
  fetchFooterMenu,
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterMenu); // Connecting component to Redux store
