import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./assets/css/common.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
//  import './service-worker.js'
import toast, { Toaster } from 'react-hot-toast';
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";

// Create a root for ReactDOM
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the App component wrapped in Provider for Redux store
root.render(
  <Provider store={store}>
    <Toaster position="bottom-right" />
    <App />
  </Provider>
);

serviceWorkerRegistration.register();



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
