// sagas.js
import { takeLatest, put, call } from "redux-saga/effects";
import { EPISODE } from "../../../utility/constants";
import axios from "axios";
import {
  FETCH_EPISODE_REQUEST,
  fetchEpisodeSuccess,
  fetchEpisodeFailure,
} from "../../actions/episodeActions";

function* fecthEpisode(action) {
  try {
    const episodeFormdata = new FormData();
    episodeFormdata.append("entryid", action.payload.entryid);

    const response = yield call(axios.post, EPISODE, episodeFormdata, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });

    if (response.status === 200 && response.data.status === "Success") {
      yield put(fetchEpisodeSuccess(response.data));
    }
  } catch (error) {
    yield put(fetchEpisodeFailure(error.message));
  }
}

export function* EpisodeSaga() {
  yield takeLatest(FETCH_EPISODE_REQUEST, fecthEpisode);
}
export default EpisodeSaga;
