// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the VIDEOINFO constant from the utility/constants file
import { VIDEOINFO } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the FETCH_VIDEO_REQUEST action type and the fetchVideoSuccess and fetchVideoFailure action creators from the videoActions file
import {
  FETCH_VIDEO_REQUEST,
  fetchVideoSuccess,
  fetchVideoFailure,
} from "../../actions/videoActions";

// Define a generator function fecthVideoData that takes an action object as an argument
function* fecthVideoData(action) {
  try {
    // Uncomment the following lines to use the // and access the payload properties

    // let type = action?.payload?.type;
    let entryId = action?.payload?.entryId;
    let catId = action?.payload?.catId;

    // Create a new FormData object to hold the request data
    const videoPayloadData = new FormData();

    // Uncomment the following line to append the cat_type data to the FormData object
    // videoPayloadData.append("cat_type", type);

    // Append the categoryid data from the catId variable to the FormData object
    videoPayloadData.append("categoryid", catId ? catId : '');

    // Append the entryid data from the entryId variable to the FormData object
    videoPayloadData.append("entryid", entryId ? entryId : '');

    // Use the call effect to make a POST request to the VIDEOINFO endpoint with the FormData
    const response = yield call(axios.post, VIDEOINFO, videoPayloadData, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the fetchVideoSuccess action with the response data as payload
      yield put(fetchVideoSuccess(response.data));
      // Uncomment the following line to return the response data
      // return response.data;
    }
  } catch (error) {
    // If an error occurs, dispatch the fetchVideoFailure action with the error response data as payload
    yield put(fetchVideoFailure(error.response.data));
  }
}

// Define a generator function videoSaga
export function* videoSaga() {
  // Use the takeLatest effect to listen for the FETCH_VIDEO_REQUEST action and run the fecthVideoData generator function when it's dispatched
  yield takeLatest(FETCH_VIDEO_REQUEST, fecthVideoData);
}

// Export the videoSaga as the default export
export default videoSaga;