import React, { useEffect, useState } from 'react'; // Import React and the useEffect hook
import PageRouters from '../PageRouters'; // Import the PageRouters component
import { messaging } from '../utility/firebaseconfig'; // Import the messaging instance from the firebaseconfig utility
import { onBackgroundMessage } from 'firebase/messaging/sw'; // Import the onBackgroundMessage function from firebase/messaging/sw
import { getToken, getMessaging, onMessage, isSupported } from 'firebase/messaging'; // Import necessary functions from firebase/messaging
import { SnackbarProvider } from '../components/snackbar/SnackbarContext'; // Import the SnackbarProvider from the SnackbarContext
import SnackbarComponent from '../components/snackbar/SnackbarComponent'; // Import the SnackbarComponent
import packageJson from '../../package.json'; // Import the package.json file
import { ModalProvider } from '../components/modal/modalContext'; // Import the ModalProvider from the modalContext
import { connect } from "react-redux"; // Import the connect function from react-redux
import { fetchPushNotificationRequest } from "../Redux/actions/nortificationActions"; // Import the fetchPushNotificationRequest action
import { useLocation } from 'react-router-dom'; // Import the useLocation hook from react-router-dom
import { PARTNERID } from "../utility/constants" // Import the PARTNERID constant from the constants utility
import toast from 'react-hot-toast'; // Import the toast function from react-hot-toast
import { Grid, Typography } from '@mui/material'; // Import Grid and Typography components from @mui/material
import NortificationBanner from '../components/NortificationBanner';
import { fetchTokenRequest } from '../Redux/actions/getTokenActions';
import { fetchCommonDataRequest } from "../Redux/actions/commonActions";


const App = ({ fetchPushNotificationRequest, dynamicItems, commonData, fetchCommonData, fetchCommonDataRequest, tokenData, fetchTokenData }) => {
  let path = window.location.pathname; // Get the current path from the window object
  let device = JSON?.parse(localStorage?.getItem('device')); // Parse the 'device' item from localStorage

  // Extract query parameters from URL
  const urlParams = new URLSearchParams(window.location.search);

  // Extract 'medium' parameter from URL query
  const medium = urlParams.get('medium');

  // Check if 'medium' parameter is valid
  let isMedium = medium && medium !== undefined && medium !== null && medium !== "" && (medium === "android" || medium === "tizen" || medium === "roku" || medium === "ios" || medium === "webos") ? true : false;

  const isAuth = localStorage.getItem("isConnected") === "true"; // Check if the user is authenticated
  const matamourl = sessionStorage.getItem("matamourl"); // Check if the user is authenticated

  const siteid = sessionStorage.getItem("siteid"); // Check if the user is authenticated

  // debugger
  const storedToken = localStorage.getItem('token');
  const [accessToken, setAccessToken] = useState('');

  useEffect(() => {
    // await Promise.all([fetchTopMenu()]);
    // testnortification();
   fetchTokenData();
  }, []);

  useEffect(() => {
    if (tokenData ?? false) {
    try {
      if (tokenData.status === "Success") {
        const responseToken = tokenData.token;
        localStorage.setItem("token", responseToken);
        localStorage.setItem("isConnected", false);
        setAccessToken(responseToken);
        fetchCommonData();
      } 
    }catch (error){}
  } 
    if(storedToken){
      fetchCommonData();
      matomojs(matamourl, siteid);
    }
    if (commonData) {

        // Check if commonData status is "Success"
        if (commonData.status === "Success") {
                // Extract templateId from commonData and store it in localStorage
                const templateId = commonData.data.template_id;
                const websiteurl = commonData.data.live_store_url.website;
                const matamourl = commonData.data.matomo.matomo_url;
                const siteurl = commonData.data.matomo.site_url;
                const siteid = commonData.data.matomo.site_id;
                sessionStorage.setItem('matamourl', matamourl)
                sessionStorage.setItem('siteurl', siteurl)
                sessionStorage.setItem('siteid', siteid)
                localStorage.setItem("templateid", templateId);
                localStorage.setItem("websiteurl", websiteurl);
                // localStorage.setItem("websiteurl", 'http://localhost:3000');
                matomojs(commonData?.data?.matomo?.matomo_url, commonData?.data?.matomo?.site_id);
              }
      } 
    caching();
  }, [tokenData]);


  useEffect(() => {

    const requestPermission = async () => {
      try {
        const permission = await Notification.requestPermission(); // Request notification permission from the user
        if (permission && permission === "granted") { // If permission is granted
          const token = await getToken(messaging, {
            vapidKey: 'BOan9QcGdeTZ12v-Qb9GT3imHGAMasSBGVbaNMvBzl5HfiAXyo9vscaOS2z-Fr04fg8VJoMTL1zFkLY-i0kw8uQ'
          });
          // console.log('Push Notification Token:', token);
          if (token ?? false) { // If token is truthy
            fetchPushNotificationRequest({
              "state": localStorage.getItem('countryCode'),
              "partnerid": PARTNERID,
              "badges": "9",
              "uuid": localStorage.getItem('uuid'),
              "tag": "new",
              "app_token": token,
              "userid": localStorage.getItem('userid') ? localStorage.getItem('userid') : '0',
              "device": `${device?.os?.name}-${device?.client?.name}`,
              "os": 'web',
              "device_model": `${device?.device?.type}-${device?.os?.name}-${device?.os?.platform}`
            })
          }
          isSupported()
            .then((supported) => {
              if (supported) { // If push messaging is supported
                console.log('Push messaging is supported in this browser.');
                const messaging = getMessaging();
                onMessage(messaging, (payload) => {
                  console.log('Received push notification (foreground):', payload);
                  if (payload?.data ?? false) {
                    try {
                      const sanitizedCatName = encodeURIComponent(payload?.data?.name.replace(/[|:;.,\s]/g, "").toLowerCase()); // Sanitizing category name for URL
                      if (payload?.data?.content_type === "video" || payload?.data?.content_type === "audio") {

                        toast.custom((t) => (
                          <NortificationBanner
                            buttontext={'Play Video'}
                            url={`/${payload?.data?.content_type === "video" ? 'watch' : 'Podcast'}/cat/${sanitizedCatName}/${payload?.data?.entryid}`}
                            entryid={payload?.data?.entryid}
                            contenttype={payload?.data?.content_type}
                            image={payload?.data?.thumb_url}
                            title={payload?.data?.title}
                            message={payload?.data?.message}
                          />
                        ), {
                          duration: 10000, // Set the duration to 10 seconds (10000 milliseconds)
                        });

                        const notificationTitle = payload.data.title;
                        const notificationOptions = {
                          body: payload.data.message,
                          icon: payload.data.thumb_url,
                          data: {
                            url: `${localStorage.getItem('websiteurl')}/${payload?.data?.content_type === "video" ? 'watch' : 'Podcast'}/cat/${sanitizedCatName}/${payload?.data?.entryid}`,
                          },
                        };

                        const notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = () => {
                          window.open(notification.data.url, '_self');
                        };
                      }
                      else if (payload?.data?.content_type === "youtube") {
                        toast.custom((t) => (
                          <NortificationBanner
                            buttontext={'Play Video'}
                            url={payload.data.Url}
                            entryid={payload?.data?.entryid}
                            contenttype={payload?.data?.content_type}
                            image={payload?.data?.thumb_url}
                            title={payload?.data?.title}
                            message={payload?.data?.message}
                          />
                        ), {
                          duration: 10000, // Set the duration to 10 seconds (10000 milliseconds)
                        });

                        const notificationTitle = payload.data.title;
                        const notificationOptions = {
                          body: payload.data.message,
                          icon: payload.data.thumb_url,
                          data: {
                            url: payload.data.Url,
                          },
                        };

                        const notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = () => {
                          window.open(notification.data.url, '_blank');
                        };
                      }
                      else if (payload?.data?.content_type === "rss_feed") {
                        toast.custom((t) => (
                          <NortificationBanner
                            buttontext={'View Rss Feeds'}
                            url={`${localStorage.getItem('websiteurl')}/feeds/cat/rssfeed/?downloadurl=${payload.data.Url}`}
                            entryid={payload?.data?.entryid}
                            contenttype={payload?.data?.content_type}
                            image={payload?.data?.thumb_url}
                            title={payload?.data?.title}
                            message={payload?.data?.message}
                          />
                        ), {
                          duration: 10000, // Set the duration to 10 seconds (10000 milliseconds)
                        });

                        const notificationTitle = payload.data.title;
                        const notificationOptions = {
                          body: payload.data.message,
                          icon: payload.data.thumb_url,
                          data: {
                            url: `${localStorage.getItem('websiteurl')}/feeds/cat/rssfeed/?downloadurl=${payload.data.Url}`,
                          },
                        };

                        const notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = () => {
                          window.open(notification.data.url, '_self');
                        };
                      }

                      else if (payload?.data?.content_type === "pdf") {
                        toast.custom((t) => (
                          <NortificationBanner
                            buttontext={'View Pdf'}
                            url={payload.data.Url}
                            entryid={payload?.data?.entryid}
                            contenttype={payload?.data?.content_type}
                            image={payload?.data?.thumb_url}
                            title={payload?.data?.title}
                            message={payload?.data?.message}
                          />
                        ), {
                          duration: 10000, // Set the duration to 10 seconds (10000 milliseconds)
                        });

                        const notificationTitle = payload.data.title;
                        const notificationOptions = {
                          body: payload.data.message,
                          icon: payload.data.thumb_url,
                          data: {
                            url: payload.data.Url,
                          },
                        };

                        const notification = new Notification(notificationTitle, notificationOptions);
                        notification.onclick = () => {
                          window.open(notification.data.url, '_blank');
                        };
                      } else { }

                    } catch (error) {

                    }
                  } else {
                    if (payload.notification ?? false) {
                      toast.custom((t) => (
                        <NortificationBanner
                          buttontext={null}
                          url={null}
                          entryid={null}
                          contenttype={null}
                          image={null}
                          title={payload.notification.title}
                          message={payload.notification.body}
                        />
                      ),
                        {
                          duration: 6000, // Set the duration to 6 seconds (6000 milliseconds)
                        }
                      );
                    }
                    const notificationTitle = payload.notification.title;

                    const notificationOptions = {
                      body: payload.notification.body,
                      icon: payload.notification.icon,
                    };

                    new Notification(notificationTitle, notificationOptions); // Show a notification
                  }

                });

              } else {
                console.log('Push messaging is not supported in this browser.');
              }
            })
            .catch((err) => {
              console.error('Check for push messaging support failed:', err);
            });
        } else {
          console.log('Push Notification permission denied');
        }
      } catch (error) {
        console.log('Error requesting push notification permission:', error);
      }
    };
    
      if (!isMedium) {
     if (commonData.status === "Success") { 
      requestPermission();
      try{
        matomojs(commonData?.data?.matomo?.matomo_url, commonData?.data?.matomo?.site_id, commonData?.data?.matomo?.site_url);

        }catch(err){}
        console.log("first", commonData?.data?.matomo?.matomo_url);  
    } else {

    }
    }
    // requestPermission();
    // if (isAuth) {

    //   requestPermission();
    // }
    caching();
  }, [commonData]);

  // useEffect(() => {
  //   // await Promise.all([fetchTopMenu()]);
  //   // testnortification();
  //   fetchCommonData();
  //   caching();

  // }, [fetchCommonData]);

  const matomojs = (matomo_url, site_id) => {
    if(matomo_url && site_id){
    let _paq = window._paq = window._paq || [];
        //  let pathname = window.location.hostname;
         let u = matomo_url;
        //  console.log("matomo_url", matomo_url);  
        //  console.log("matomo_url", site_id); 
         _paq.push(['setUserId', 'guest user']);
         _paq.push(['trackPageView']);
         _paq.push(['enableLinkTracking']);
         _paq.push(['setTrackerUrl', u + '/matomo.php']);
         _paq.push(['setSiteId', site_id]);
       
         let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
         g.type = 'text/javascript';
         g.async = true;
         g.src = u + '/matomo.js';
         s.parentNode.insertBefore(g, s);
 } else{}
        
   
 }


  const caching = () => {

    let version = localStorage.getItem("version") || ""; // Get the current version from localStorage
    if (version !== packageJson.version) { // If the current version is different from the package.json version
      if ("caches" in window) { // If the browser supports caching
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName); // Delete all caches
          });
        });
      }
      window.location.reload(true); // Reload the page
      localStorage.clear(); // Clear localStorage
      localStorage.setItem("version", packageJson.version); // Set the new version in localStorage
    }
  };
  const testnortification = async () => {
    toast.custom((t) => (
      <NortificationBanner
        buttontext={'View More'}
        url={`http://localhost:3000/watch/cat/nadhanodoppam/s_o9nzfeg1`}
        entryid={'s_o9nzfeg1'}
        contenttype={'video'}
        image={'https://cdn.pixabay.com/photo/2017/07/24/19/57/tiger-2535888_1280.jpg'}
        title={'Know Your Indian Wild Cats'}
        message={"While many know of Wildlife SOS’ leopard conservation efforts, a little-known fact is that we have also had the opportunity to study and work with tigers, lions, and some of the lesser-known Indian wild cats such as the Rusty-spotted cat, Jungle cat, and Leopard cat."}
      />
    ), {
      duration: 100000, // Set the duration to 10 seconds (10000 milliseconds)
    });

  }
  if (!storedToken) {
    return null;
  }
  return (
    <>
      <ModalProvider>
        <SnackbarProvider>
          <SnackbarComponent />
          <PageRouters />
        </SnackbarProvider>
      </ModalProvider>
    </>
  );
};


const mapStateToProps = (state) => ({
  nortificationItems: state.nortification.pushnotification,
  commonData: state.common.commonData,
  isLoading: state.common.isLoading,
  tokenData: state.getToken.tokenData,
});

const mapDispatchToProps = (dispatch) => ({
  fetchPushNotificationRequest: (params) => dispatch(fetchPushNotificationRequest(params)),
  fetchCommonData: () => dispatch(fetchCommonDataRequest()),
  fetchTokenData: () => dispatch(fetchTokenRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);