// Import necessary functions and effects from the redux-saga library
import { takeLatest, put, call } from "redux-saga/effects";

// Import the UPDATEUSERPROFILE constant from the utility/constants file
import { UPDATEUSERPROFILE } from "../../../utility/constants";

// Import the Axios library for making HTTP requests
import axios from "axios";

// Import the UPDATE_USER_PROFILE action type and the updateProfileSuccess and updateProfileFailure action creators from the updateProfileActions file
import {
  UPDATE_USER_PROFILE,
  updateProfileSuccess,
  updateProfileFailure,
} from "../../actions/updateProfileActions.js";

// Define a generator function updateProfile that takes an action object as an argument
function* updateProfile(action) {
  try {
    // Trim any leading/trailing whitespace from the mobile number
    let mobilenumber = action.payload.mobile.trim();

    // Create a new FormData object to hold the request data
    const profilePayload = new FormData();
    // Append the name data from the action payload to the FormData object
    profilePayload.append("name", action.payload.name);
    // Uncomment the following line to append the username data to the FormData object
    // profilePayload.append("username", username);
    // Append the countrycode data from the action payload to the FormData object
    profilePayload.append("countrycode", action.payload.countrycode);
    // Append the gender data from the action payload to the FormData object
    profilePayload.append("gender", action.payload.gender);
    // Append the trimmed mobile number to the FormData object
    profilePayload.append("mobile", mobilenumber);

    // Use the call effect to make a POST request to the UPDATEUSERPROFILE endpoint with the FormData
    const response = yield call(axios.post, UPDATEUSERPROFILE, profilePayload, {
      headers: {
        token: localStorage.getItem("token"), // Include the token from localStorage in the request headers
      },
    });

    // Check if the response status is 200 (OK) and the status property of the response data is "Success"
    if (response.status === 200 && response.data.status === "Success") {
      // If the response is successful, dispatch the updateProfileSuccess action with the response data as payload
      yield put(updateProfileSuccess(response.data));
      // Uncomment the following line to return the response data
      // return response.data;
    }
  } catch (error) {
    // If an error occurs, dispatch the updateProfileFailure action with the error message as payload
    yield put(updateProfileFailure(error.message));
  }
}

// Define a generator function updatedProfileSaga
export function* updatedProfileSaga() {
  // Use the takeLatest effect to listen for the UPDATE_USER_PROFILE action and run the updateProfile generator function when it's dispatched
  yield takeLatest(UPDATE_USER_PROFILE, updateProfile);
}

// Export the updatedProfileSaga as the default export
export default updatedProfileSaga;