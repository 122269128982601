import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Import internal (own) modules
import FullPageLayout from "./layouts/routes/fullRoutes";
import ErrorPageLayout from "./layouts/routes/errorRoutes";
import PublicPageLayout from "./layouts/routes/publicRoutes";
import PrivatePageLayout from "./layouts/routes/privateRoutes";
// import { useHistory } from "react-router-dom";
// Loader components
import HomeSkeleton from "../components/Skeleton/HomeSkeleton";
import LoaderSpinner from "../components/Spinner";

// Import lazy-loaded pages
const LazyHome = lazy(() => import("../views/home"));
const LazyViewAll = lazy(() => import("../views/viewall/index"));
const LazySearchResult = lazy(() => import("../views/search"));
const LazyWatch = lazy(() => import("../views/watch"));
const LazyDataDeletion = lazy(() => import("../views/datadeletion"));
const LazyNotFound = lazy(() => import("../views/notFound"));
const LazyProfileSetting = lazy(() => import("../views/profileSetting"));
const LazyPlans = lazy(() => import("../views/plans"));
const LazyStaticPage = lazy(() => import("../views/staticPages"));
const LazyTransactionHistory = lazy(() =>
  import("../views/transactionhistory")
);
const LazyLanguageSetting = lazy(() => import("../views/LanguageSetting"));
const LazyDeviceManagement = lazy(() => import("../views/devicemanagement"));
const LazyMyList = lazy(() => import("../views/myList/index"));
const LazyDemo = lazy(() => import("../views/demopage"));
const LazyFaq = lazy(() => import("../views/faq"));
const LazyContactUs = lazy(() => import("../views/contactUs"));
const LazyAboutUs = lazy(() => import("../views/aboutUs"));
const LazyPrivacyPolicy = lazy(() => import("../views/privacyPolicy"));
const LazyTermsandcondition = lazy(() => import("../views/termsandCondition"));
const LazyRadio = lazy(() => import("../views/Radio"));
const LazyRadioPlayer = lazy(() => import("../views/Radioview"));
const LazyTvSchedule = lazy(() => import("../views/tvschedule"));
const LazyRssFeed = lazy(() => import("../views/RssFeeds"));
const LazyPrayerRequest = lazy(() => import("../views/prayerRequest"));
const LazyDynamicPages = lazy(() => import("../views/dynamicPages"));

const PageRouters = (props) => {
  // const history = useHistory();
  // console.log('history', history)
  return (
    <Router basename="/">

      <Switch>
        {/* Route for home page */}
        <PublicPageLayout
          exact
          isPrivate={true}
          path="/"
          render={(matchprops) => (
            <Suspense>
              <LazyHome {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for device management */}
        <PublicPageLayout
          exact
          isPrivate={true}
          path="/device-management"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyDeviceManagement {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for data deletion instructions */}
        <PublicPageLayout
          exact={true}
          path="/data-deletion-instructions"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyDataDeletion {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for DynamicFooterPages */}
        <PublicPageLayout
          exact={true}
          path="/static/:pagename"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyDynamicPages {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for FAQ */}
        <PublicPageLayout
          exact={true}
          path="/faq"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyFaq {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for RSS feeds */}
        <PublicPageLayout

          path="/feeds/:cattype/:menuitemname/:menucategoryId"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyRssFeed {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for RSS feeds */}
        <PublicPageLayout

          path="/feeds/:cattype/:menuitemname"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyRssFeed {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for radio */}
        <PublicPageLayout
          exact={true}
          path="/radio/:menucategoryId"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyRadio {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for viewing all items */}
        <PublicPageLayout
          exact={true}
          path="/viewall/:cattype/:menuitemname/:menucategoryId"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyViewAll {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for search results */}
        <PublicPageLayout
          exact={true}
          path="/search"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazySearchResult {...matchprops} />
            </Suspense>
          )}
        />
        <PublicPageLayout
          exact={true}
          path="/search/:keyword"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazySearchResult {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for My List */}
        <PublicPageLayout
          exact
          path="/my-list"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyMyList {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for transaction history */}
        <PublicPageLayout
          exact
          path="/transaction-history"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyTransactionHistory {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for watching */}
        <PublicPageLayout
          exact
          path="/watch/:type/:categoryName/:id"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyWatch {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for language settings */}
        <PublicPageLayout
          exact
          path="/language-setting"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyLanguageSetting {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for account settings */}
        <PublicPageLayout
          exact
          path="/account-setting"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyProfileSetting {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for about us page */}
        <PublicPageLayout
          exact
          path="/about-us"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyAboutUs {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for contact us page */}
        <PublicPageLayout
          exact
          path="/contact-us"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyContactUs {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for terms and conditions */}
        <PublicPageLayout
          exact
          path="/termandcondition"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyTermsandcondition {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for privacy policy */}
        <PublicPageLayout
          exact
          path="/privacy-policy"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyPrivacyPolicy {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for radio player */}
        <PublicPageLayout
          exact
          path="/radioview/:type/:categoryName/:id"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyRadioPlayer {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for TV schedule */}
        <PublicPageLayout
          exact
          path="/tvschedule/:id"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyTvSchedule {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for prayer requests */}
        <PublicPageLayout
          exact
          path="/prayer/:id"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyPrayerRequest {...matchprops} />
            </Suspense>
          )}
        />
        {/* Route for browse requests */}
        <PublicPageLayout
          exact={true}
          path="/browse/:menuitemname/:menucategoryId"
          render={(matchprops) => (
            <Suspense fallback={<LoaderSpinner />}>
              <LazyHome {...matchprops} /> </Suspense>)} />
      </Switch>
    </Router>
  );
};

export default PageRouters;
