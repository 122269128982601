import Button from "@mui/material/Button"; // Importing Button component from Material-UI
import React, { useEffect, useState, useContext } from "react"; // Importing necessary modules from React
import { connect } from "react-redux"; // Importing connect function from react-redux
import { fetchLogoutRequest } from "../../../../Redux/actions/logoutAction"; // Importing fetchLogoutRequest action
import { SnackbarContext } from "../../../../components/snackbar/SnackbarContext"; // Importing SnackbarContext from custom snackbar component
import { useHistory } from "react-router-dom"; // Importing useHistory hook from react-router-dom
import LogoutIcon from "@mui/icons-material/Logout"; // Importing LogoutIcon component from Material-UI

const Logout = ({
  logoutData,
  isLoading,
  error,
  fetchLogout,
  handleClose,
  uuid,
}) => {
  // Hooks
  const history = useHistory(); // useHistory hook for navigation
  const { showSnackbar } = useContext(SnackbarContext); // useContext hook to access snackbar context

  // Effects
  useEffect(() => {
    if (logoutData && logoutData.status === "Success") {
      // Clear local storage after a 5-second delay
      localStorage.clear();
      localStorage.setItem("isConnected", false);
      window.location.reload(); // Reload the page after logout
      // setTimeout(() => {
      //   // window.location.assign("/");
      //   // history.push();
      // }, 6000);
    } if (logoutData && logoutData.status === "Success") {

    }

  }, [logoutData]);

  // Event Handlers
  const handleLogout = async () => {
    await fetchLogout({
      "uuid": uuid
    });
    handleClose("");
  };

  // Render
  return (
    <div className="px-3 py-2">
      <Button
        className="cursor bg-red text-center logoutBtn btn-lg"
        onClick={() => handleLogout()}
      >
        <LogoutIcon className="text-white font-26 mx-2" />
        <span>Logout</span>
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  logoutData: state.logout.logoutData,
  isLoading: state.isLoading,
  error: state.error,
});

const mapDispatchToProps = {
  fetchLogout: fetchLogoutRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout); // Connecting component to Redux store
